import { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-bootstrap';
import colors from '../../common/colors';

const StyledMultiSelect = styled(MultiSelect)`
	.gray {
		color: ${colors.placeholderText};
	}
	.dropdown-content {
		z-index: 10;
	}
`;

const localization = {
	allItemsAreSelected: 'Alle Einträge sind ausgewählt.',
	clearSearch: 'Suche löschen',
	clearSelected: 'Ausgewählte löschen',
	noOptions: 'Keine Optionen',
	search: 'Suche',
	selectAll: 'Alle auswählen',
	selectAllFiltered: 'Alle gefilterten auswählen',
	selectSomeItems: 'Auswählen...',
	create: 'Anlegen'
};

export function MultiSelector({
	options,
	initValues,
	onChange,
	label,
	inDialog,
	className,
	controlId,
	visuallyHidden = false
}) {
	const [selectedValues, setSelectedValues] = useState();

	const handleChange = (values) => {
		onChange(values.map((item) => item.value));
	};

	useEffect(() => {
		if (!initValues || !options) return;

		const populatedSelectedValues = initValues.map((x) => options.find((y) => y.value === x));

		if (!populatedSelectedValues) return;

		setSelectedValues(populatedSelectedValues);
		// eslint-disable-next-line
	}, [options]);

	if (isMobile)
		return (
			<>
				<Form.Group className={className} controlId={controlId}>
					<Form.Label visuallyHidden={visuallyHidden}>{label}</Form.Label>
					<Form.Control
						defaultValue={initValues}
						as="select"
						multiple
						onChange={(e) =>
							onChange([].slice.call(e.target.selectedOptions).map((item) => item.value))
						}
					>
						{options?.map((item) => (
							<option key={item.value} value={item.value}>
								{item.label}
							</option>
						))}
					</Form.Control>
				</Form.Group>
			</>
		);

	return (
		<Form.Group className={className} controlId={controlId}>
			<Form.Label visuallyHidden={visuallyHidden}>
				<strong>{label}</strong>
			</Form.Label>
			<div id={controlId}>
				<StyledMultiSelect
					options={options ?? []}
					value={selectedValues ?? []}
					onChange={handleChange}
					labelledBy={label}
					id={controlId}
					hasSelectAll={false}
					overrideStrings={localization}
					inDialog={inDialog}
				/>
			</div>
		</Form.Group>
	);
}
