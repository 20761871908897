import { useState, useEffect } from 'react';
import {
	Accordion,
	Col,
	Form,
	FormControl,
	InputGroup,
	Row,
	Button,
	Container
} from 'react-bootstrap';
import { GrLocation, GrSelect, GrTarget } from 'react-icons/gr';
import {
	HiOutlineChevronDown,
	HiOutlineChevronUp,
	HiOutlineStatusOffline,
	HiOutlineStatusOnline
} from 'react-icons/hi';
import { FaWheelchair } from 'react-icons/fa';
import { BsFillInboxesFill } from 'react-icons/bs';

import { RegionSelector } from '../domain.core';
import styled from 'styled-components';


const SearchColumn = styled(Col)`
	padding: 0;
	margin-left: -1;
`;

const SearchInput = styled(Form.Control)`
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	height: 100%;
	&:focus {
		z-index: 10;
		position: relative;
    }
`;

const RegionColumn = styled(Col)`
	padding: 0;
	margin-left: -1;
`;

const StyledRegionSelector = styled(RegionSelector)`
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
`;

const today = new Date().toISOString().split('T')[0];

export function Search({ onSearchDataChanged, startSearch, regionSelection }) {
	const defaultFormData = {
		text: '',
		regionen_id: 0,
		bool: ' AND ',
		einrichtungen_barrierefrei: 0,
		gesamtstaedtische_schwerpunkte_id: 0,
		kurse_angebotsart: '',
		veranstaltungen_art: '',
		veranstaltungErsterTermin: '',
		limit: 0,
		limit_ab: 0,
		alleThemen: false,
		zielgruppe_schulstufe_id: '',
	};

	const [formData, setFormData] = useState(defaultFormData);
	const getData = (key) => {
		return Object.prototype.hasOwnProperty.call(formData, key) ? formData[key] : '';
	};
	const setData = (key, value) => {
		if (key === 'text') {
			// console.log('Hallo: ', (key, value));
			if (value !== '') return setFormData({ ...formData, [key]: value, alleThemen: true });
			else return setFormData({ ...formData, [key]: value, alleThemen: false });
		}
		return setFormData({ ...formData, [key]: value });
	};
	const onResetForm = () => {
		setFormData(defaultFormData);
	};

	useEffect(() => {
		if (startSearch)
			onSearchDataChanged({
				searchData: formData
			});
		// eslint-disable-next-line
	}, []);

	const onFormSubmit = (event) => {
		event.preventDefault();
		onSearchDataChanged({
			searchData: formData
		});
	};

	return (
		<>
			<Col>
				<Form
					aria-describedby="search_instruction"
					style={{ marginTop: '1em', marginBottom: '1em' }}
				>
					<Container className="mb-3">
						<Row xs={12} lg={3}>
							<SearchColumn lg = {regionSelection ? 6 : 12}>
								<Form.Label visuallyHidden={true} htmlFor="search_searchText">
									Geben Sie den Suchtext ein
								</Form.Label>
								<SearchInput
									id="search_searchText"
									name="text"
									type="text"
									placeholder="Unterrichtsfach, Stichwort, Region, Schulart ... eingeben"
									value={getData('text')}
									aria-describedby="Geben Sie den Suchtext ein"

									onChange={(e) => {
										setData('text', e.target.value);
									}}

									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											e.preventDefault();
											onFormSubmit(e);
										}
									}}
								/>
							</SearchColumn>
							{regionSelection && (
								<RegionColumn lg={6}>
									<StyledRegionSelector
										onChange={(values) => setData('regionen_id', values)}
										initValues={getData('regionen_id')}
									/>
								</RegionColumn>)
							}
						</Row>
						<Row xs={1} lg={2} style={{ gap: '15px 0px', marginTop: '1em' }}>
							<Col>
								<Button
									style={{ width: '100%' }}
									variant="outline-success"
									onClick={onFormSubmit}
									aria-label="Fortbildungen suchen"
								>
									Suchen
								</Button>
							</Col>
							<Col>
								<Button
									style={{ width: '100%' }}
									variant="outline-danger"
									onClick={onResetForm}
									aria-label="Suchfilter zurücksetzen"
								>
									Zurücksetzen
								</Button>
							</Col>
						</Row>
					</Container>

					<container className="mb-3">
						<Accordion defaultActiveKey="2">
							<Accordion.Item eventKey="0">
								<Accordion.Header as="h3">
									{' '}
									Erweiterte Suche von Fortbildungen &nbsp;
								</Accordion.Header>
								<Accordion.Body>
									<fieldset>
										<legend className="visually-hidden">Erweiterte Suchefilter</legend>
										<Row xs={1} lg={2}>
											<Col>
												<InputGroup className="mb-2">
													<Form.Label visuallyHidden={true} htmlFor="search_searchLink">
														Alle Suchbegriffe oder irgendein Suchbegriff
													</Form.Label>
													<Form.Select
														id="search_searchLink"
														value={getData('bool')}
														onChange={(e) => setData('bool', e.target.value)}
														aria-describedby="Alle Suchbegriffe oder irgendein Suchbegriff"
													>
														<option value={' AND '}>Alle Suchbegriffe müssen zutreffen</option>
														<option value={' OR '}>Irgendein Suchbegriff muss zutreffen</option>
													</Form.Select>
													<InputGroup.Text>
														{getData('bool') === ' AND ' ? (
															<HiOutlineChevronUp />
														) : (
															<HiOutlineChevronDown alt="Auswahl Suchbegriffe Icon" />
														)}
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup className="mb-2">
													<Form.Label visuallyHidden={true} htmlFor="search_institutionAccessible">
														Barrierefreie Fortbildungsorte berücksichtigen oder nicht
													</Form.Label>
													<Form.Select
														id="search_institutionAccessible"
														value={getData('einrichtungen_barrierefrei')}
														onChange={(e) => setData('einrichtungen_barrierefrei', e.target.value)}
														aria-describedby="Barrierefreie Fortbildungsorte berücksichtigen oder nicht"
													>
														<option value={0}>Alle Fortbildungsorte</option>
														<option value={1}>Nur barrierefreie Fortbildungsorte</option>
													</Form.Select>
													<InputGroup.Text>
														{getData('einrichtungen_barrierefrei') === '1' ? (
															<FaWheelchair alt="Barrierefreie Einrichtungen Icon" />
														) : (
															<GrLocation alt="Alle Einrichtungen Icon" />
														)}
													</InputGroup.Text>
												</InputGroup>
											</Col>
										</Row>

										<Row xs={1} lg={2}>
											<Col>
												<InputGroup className="mb-2">
													<Form.Label visuallyHidden={true} htmlFor="search_focuses">
														Gesamtstädtische Schwerpunkte wählen
													</Form.Label>
													<Form.Select
														id="search_focuses"
														value={getData('gesamtstaedtische_schwerpunkte_id')}
														onChange={(e) =>
															setData('gesamtstaedtische_schwerpunkte_id', e.target.value)
														}
														aria-describedby="Gesamtstädtische Schwerpunkte wählen"
													>
														<option value={0}>Alle gesamtstädtischen Schwerpunkte</option>
														<option value={159}>Kompetenzen für eine politische Mündigkeit</option>
														<option value={155}>
															Kompetenzen für inklusives und soziales Lernen im Ganztag
														</option>
														<option value={157}>
															Mathematische und sprachliche Kompetenzen in Verbindung mit
															Heterogenität und Individualisierung
														</option>
														<option value={156}>Medienkompetenzen</option>
													</Form.Select>
													<InputGroup.Text>
														<GrTarget alt="Gesamtstädtischen Schwerpunkte Icon" />
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup className="mb-2">
													<Form.Label visuallyHidden={true} htmlFor="search_offerType">
														Angebotsarten wählen
													</Form.Label>
													<Form.Select
														id="search_offerType"
														value={getData('kurse_angebotsart')}
														onChange={(e) => setData('kurse_angebotsart', e.target.value)}
														aria-describedby="Angebotsarten wählen"
													>
														<option value={''}>Alle Angebotsarten</option>
														<option value={'Zentral'}>Nur Zentral</option>
														<option value={'Regional'}>Nur Regional</option>
													</Form.Select>
													<InputGroup.Text>
														<GrSelect alt="Angebotsarten Icon" />
													</InputGroup.Text>
												</InputGroup>
											</Col>
										</Row>

										<Row xs={1} lg={2}>
											<Col>
												<InputGroup className="mb-2">
													<Form.Label visuallyHidden={true} htmlFor="search_eventType">
														Fortbildungsarten, Online etc. wählen
													</Form.Label>
													<Form.Select
														id="search_eventType"
														value={getData('veranstaltungen_art')}
														onChange={(e) => setData('veranstaltungen_art', e.target.value)}
														aria-describedby="Fortbildungsarten, Online etc. wählen"
													>
														<option value={''}>Alle Fortbildungsarten</option>
														<option value={'Präsenzveranstaltung'}>Präsenz-Fortbildung</option>
														<option value={'Online-Kurs'}>Online-Fortbildung</option>
														<option value={'Blended-Learning-Kurs'}>
															Blended-Learning-Fortbildung
														</option>
													</Form.Select>
													<InputGroup.Text>
														{getData('veranstaltungen_art') === 'Online-Kurs' ? (
															<HiOutlineStatusOnline alt="Onlinekurs Icon" />
														) : (
															<HiOutlineStatusOffline alt="Präsenskurs Icon" />
														)}
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup className="mb-2">
													<InputGroup.Text>
														ab:
													</InputGroup.Text>
													<Form.Label visuallyHidden={true} htmlFor="search_firstDate">
														Fortbildungen ab einem bestimmten Datum wählen
													</Form.Label>
													<FormControl
														id="search_firstDate"
														name="veranstaltungErsterTermin"
														type="date"
														value={getData('veranstaltungErsterTermin') || today}
														onChange={(e) => setData('veranstaltungErsterTermin', e.target.value)}
														aria-describedby="Fortbildungen an einem bestimmten Datum wählen"
													/>

												</InputGroup>
											</Col>
										</Row>
										<Row xs={1} lg={2}>
											<Col lg={6}>
												<InputGroup className="mb-3">
													<Form.Label visuallyHidden={true} htmlFor="search_interests">
														Sollen alle Interessen angezeigt werden?
													</Form.Label>
													<Form.Select
														id="search_interests"
														name="alleThemen"
														value={getData('alleThemen')}
														onChange={(e) => setData('alleThemen', e.target.value === 'true')}
														aria-describedby="Sollen alle Interessen angezeigt werden?"
													>
														<option value={true}>Interessen nicht berücksichtigen</option>
														<option value={false}>Persönlichen Interessen berücksichtigen</option>
													</Form.Select>
													<InputGroup.Text>
														<BsFillInboxesFill alt="Interessen Icon" />
													</InputGroup.Text>
												</InputGroup>
											</Col>
											<Col lg={6}>
												<InputGroup className="mb-3">
													<Form.Label visuallyHidden={true} htmlFor="search_zielgruppe_schulstufe">
														Zielgruppe Schulstufe auswählen
													</Form.Label>
													<Form.Select
														id="search_zielgruppe_schulstufe"
														name="zielgruppe_schulstufe_id"
														value={getData('zielgruppe_schulstufe_id')}
														onChange={(e) => setData('zielgruppe_schulstufe_id', e.target.value)}
														aria-describedby="Zielgruppe Schulstufe auswählen"
													>
														<option value={''}>Keine Einschränkung der Schulstufen vornehmen</option>
														<option value={1}>Angebote für alle Schulstufen</option>
														<option value={2}>Grundschule / Förderzentren</option>
														<option value={3}>Sek I</option>
														<option value={4}>Sek II</option>
														<option value={5}>Sek I und Sek II</option>
														<option value={7}>Angebote für berufliche Schulen</option>
														<option value={8}>Angebote für ISS</option>
														<option value={9}>Angebote für weiterführende Schulen</option>
														<option value={10}>Angebote für Gymnasien</option>
														<option value={11}>Angebote für Grundschule / Sek I</option>
													</Form.Select>
													<InputGroup.Text>
														<GrSelect alt="Zielgruppe Schulstufe Icon" />
													</InputGroup.Text>
												</InputGroup>
											</Col>
										</Row>
										<Row xs={1} lg={2} style={{ gap: '15px 0px' }}>
											<Col>
												<Button
													style={{ width: '100%' }}
													variant="outline-success"
													onClick={onFormSubmit}
													aria-label="Fortbildungen suchen"
												>
													Suchen
												</Button>
											</Col>
											<Col>
												<Button
													style={{ width: '100%' }}
													variant="outline-danger"
													onClick={onResetForm}
													aria-label="Suchfilter zurücksetzen"
												>
													Zurücksetzen
												</Button>
											</Col>
										</Row>
									</fieldset>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</container>
				</Form>
			</Col>
		</>
	);
}
