import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import logoHead from './img/imprint.jpg';
import { Title, SubTitle } from './Title';
import styled from 'styled-components';
import { HorizontalDivider, Paragraph, ParagraphWithSpace } from '../core';

const Indented = styled.div`
	padding-left: 2rem;
`;

const IndentedParagraph = styled.p`
	padding-left: 2rem;
`;

const NoneStyledUnorderedList = styled.ul`
	list-style-type: none;
`;

const Imprint = () => {
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		document.title = 'Impressum | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />
				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
				<SubTitle title="Impressum" />
				<HorizontalDivider />
				<h3>Herausgeberin</h3>
				<Indented>
					<Paragraph>Senatsverwaltung für Bildung, Jugend und Familie</Paragraph>
					<Paragraph>Bernhard-Weiß-Str. 6 </Paragraph>
					<ParagraphWithSpace>10178 Berlin</ParagraphWithSpace>
				</Indented>
				<hr />
				<h3>Koordinierende Redaktion</h3>
				<NoneStyledUnorderedList>
					<li>
						<h4>Annett Kreuziger (II E 6)</h4>
						<Paragraph>Senatsverwaltung für Bildung, Jugend und Familie</Paragraph>
						<Paragraph>Bernhard-Weiß-Str. 6, 10178 Berlin</Paragraph>
						<ParagraphWithSpace>
							Sprechzeiten: Montag bis Freitag nach Vereinbarung
						</ParagraphWithSpace>
					</li>

					<li>
						<h4>Ruth Schaefer-Franke (II E 6.2) </h4>
						<Paragraph>Senatsverwaltung für Bildung, Jugend und Familie</Paragraph>
						<Paragraph>Bernhard-Weiß-Str. 6, 10178 Berlin</Paragraph>
						<ParagraphWithSpace>
							Sprechzeiten: Montag bis Freitag nach Vereinbarung
						</ParagraphWithSpace>
					</li>
				</NoneStyledUnorderedList>
				<hr />
				<h3>Server, Programmierung, Datenbank und Design</h3>
				<IndentedParagraph>Jörg-Olaf Wehner, Lutz Westphal, Janik Hilser</IndentedParagraph>
				<hr />
				<h3>Haftungsausschluss</h3>
				<Indented>
					<ParagraphWithSpace>
						Die von der Senatsverwaltung für Bildung, Jugend und Familie veröffentlichen Inhalte
						sind mit größter Sorgfalt recherchiert. Dennoch können wir keine Gewähr für ihre
						Richtigkeit übernehmen. Alle Angaben erfolgen ohne Gewähr. Eine Haftung für Schäden, die
						sich aus der Verwendung der veröffentlichten Inhalte ergeben, ist ausgeschlossen.
					</ParagraphWithSpace>
					<ParagraphWithSpace>
						Die Senatsverwaltung für Bildung, Jugend und Familie übernimmt keine Haftung für
						Hyperlinks, auf die diese Website verweist und ist für den Inhalt einer Internetseite,
						die mit einem solchen Link erreicht wird, nicht verantwortlich.
					</ParagraphWithSpace>
				</Indented>
				<hr />
				<h3>Urheberrecht</h3>
				<IndentedParagraph>
					Das Layout der Seiten der Senatsverwaltung für Bildung, Jugend und Familie, die
					verwendeten Grafiken sowie die Sammlung der Beiträge sind urheberrechtlich geschützt. Die
					Seiten dürfen ausschließlich zum privaten Gebrauch vervielfältigt werden. Textliche und
					grafische Änderungen sowie die öffentliche Verbreitung sind nicht erlaubt.
				</IndentedParagraph>
				<hr />
				<h3>E-Mail</h3>
				<IndentedParagraph className="pb-5">
					E-Mail-Adresse: <a href="mailto:rf@senbjf.berlin.de">rf@senbjf.berlin.de</a>
				</IndentedParagraph>
			</main>
		</>
	);
};

export default Imprint;
