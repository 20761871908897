import React, { useState } from 'react';
import './navigation.css';
import { Navbar, Nav, Image, Button, Container, Offcanvas, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import useUser from '../hooks/useUser';
import logoHead from './pages/img/brandLogo.png';
import { HiOutlineSearch } from 'react-icons/hi';
import { HorizontalDivider } from './core';

const Navigation = () => {
	const location = useLocation();
	const { isLoggedIn, logout } = useUser();
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const handleClose = () => setMenuOpen(false);

	const isActive = (path) => location.pathname === path;

	return (
		<>
			<Navbar collapseOnSelect sticky="top" expand="lg" bg="light" aria-label="Navigationsleiste">
				<Container fluid style={{ flexWrap: 'nowrap' }}>
					<Navbar.Brand as={Link} to="/">
						<Image
							style={{ height: '45px' }}
							src={logoHead}
							title="Logo der Fortbildung Berlin"
							alt="Logo der Fortbildung Berlin"
							fluid
						/>
					</Navbar.Brand>

					<Navbar.Toggle onClick={toggleMenu} aria-controls="Umschaltbare Navigationsleiste" />

					<Navbar.Offcanvas
						id={'offcanvasNavbar-expand'}
						aria-label="Navigationsleiste anzeigen"
						placement="start"
						autoFocus={true}
						restoreFocus={true}
						keyboard={true}
						show={menuOpen}
						onHide={handleClose}
					>
						<Offcanvas.Header
							closeButton
							closeLabel="Schließen"
							title="Navigation auf einer kleinen Oberfläche"
						>
							<Offcanvas.Title id={'Titel-FORTBILDUNG-BERLIN'} title="Mobiles Menü">
								<Image
									style={{ height: '45px' }}
									src={logoHead}
									title="Logo der Fortbildung Berlin"
									alt="Logo der Fortbildung Berlin"
									fluid
								/>
							</Offcanvas.Title>
						</Offcanvas.Header>

						<Offcanvas.Body>
							<Nav className="justify-content-begin flex-grow-1 pe-3" as="ul">
								<li key="find">
									<Nav.Link
										className={`no-wrap ${isActive('/find') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/find"
									>
										<strong>
											<HiOutlineSearch/> SUCHEN
										</strong>
									</Nav.Link>
								</li>
								<li key="schif">
									<Nav.Link
										className={`no-wrap ${isActive('/schif') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/schif"
									>
										SchiF
									</Nav.Link>
								</li>
								<li key="conferences">
									<Nav.Link
										className={isActive('/conferences') ? 'active-link' : ''}
										onClick={handleClose}
										as={Link}
										to="/conferences"
									>
										Regionalkonferenzen
									</Nav.Link>
								</li>
								<li key="symposiums">
									<Nav.Link
										className={`no-wrap ${isActive('/symposiums') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/symposiums"
									>
										Fachtage
									</Nav.Link>
								</li>

								<NavDropdown title="Weitere Angebote" id="weitere-angebote-dropdown">
									<NavDropdown.Item as={Link} to="/leadership" onClick={handleClose}>
										Leadership.lab
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/berufsschulen" onClick={handleClose}>
										Berufliche Schulen
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/extern" onClick={handleClose}>
										Externe Anbieter
									</NavDropdown.Item>
								</NavDropdown>

								<li key="faq">
									<Nav.Link
										className={`no-wrap ${isActive('/faq/0') ? 'active-link' : ''}`}
										onClick={handleClose}
										as={Link}
										to="/faq/0"
									>
										FAQ
									</Nav.Link>
								</li>
							</Nav>
							<HorizontalDivider/>

							{isLoggedIn ? (
								<div style={{display: 'flex', flexWrap: 'nowrap'}}>
									<Button
										variant="outline-success"
										aria-label="Profildaten ändern"
										as={Link}
										onClick={handleClose}
										to="/profile"
									>
										Profil
									</Button>
									<Button variant="outline-danger mx-2" onClick={logout}>
										Logout
									</Button>
								</div>
							) : (
								<div style={{ display: 'flex', flexWrap: 'nowrap' }}>
									<Button
										variant="outline-success mx-2"
										as={Link}
										onClick={handleClose}
										to="/login"
										state={{ backgroundLocation: location }}
									>
										Login
									</Button>
									<Button
										variant="outline-dark mx-2"
										as={Link}
										onClick={handleClose}
										to="/register"
										state={{ backgroundLocation: location }}
									>
										Registrieren
									</Button>
								</div>
							)}
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	);
};

export default Navigation;