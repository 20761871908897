import { Card, Row, Col } from 'react-bootstrap';
import { GrGroup, GrHistory, GrLocation, GrZoomIn } from 'react-icons/gr';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { BsBookmarkPlus, BsBookmarkDashFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

import {useMutation, useQueryClient} from "react-query";
import useEnrollment from '../../hooks/useEnrollment';
import useNotification from '../../hooks/useNotification';
import { TrainingFeedback } from './trainingFeedback';
import EnrollmentService from '../../services/enrollments.service';
import colors from '../../common/colors';
import styled from 'styled-components';
import {getErrorMessage} from "../../common";


const TrainingHeader = styled.div`
	hyphens: auto;
	font-size: large;
	color: ${(props) => props.color};
`;

export function TrainingCard({ training, level = 3 }) {
	const location = useLocation();
	const queryClient = useQueryClient();
	const { setInfo, setError } = useNotification();
	const { enrollment } = useEnrollment(training.id);

	const { mutate: setBookmark } = useMutation(
		(data) => EnrollmentService.addBookmark(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["home"]);
				queryClient.invalidateQueries(["conferences"]);
				queryClient.invalidateQueries(["schifs"]);
				queryClient.invalidateQueries(["leadership"]);
				queryClient.invalidateQueries(["symposiums"]);
				queryClient.invalidateQueries(["bookmarks"]);
			},
			onError: (error) => setError(getErrorMessage(error))
		}
	)

	const { mutate: delBookmark } = useMutation(
		(data) => EnrollmentService.delBookmark(data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(["home"]);
				queryClient.invalidateQueries(["conferences"]);
				queryClient.invalidateQueries(["schifs"]);
				queryClient.invalidateQueries(["leadership"]);
				queryClient.invalidateQueries(["bookmarks"]);
			},
			onError: (error) => setError(getErrorMessage(error))
		}
	)

	const getBorderColor = () => {
		// Keine Buchung
		if (!enrollment) return colors.primaryBorder;
		// War anwesend
		else if (enrollment.wasPresent) return colors.successBorder;
		// Buchung bestätigt
		else if (enrollment.isConfirmed) return colors.successBorder;
		// Abgelehnt
		else if (enrollment.isRejected) return colors.errorBorder;
		// Abgesagt von SenBJF
		else if (enrollment.isCancelledByManagement) return colors.errorBorder;
		// Abgesagt vom Benutzer
		else if (enrollment.isCancelledByUser) return colors.errorBorder;
		// Auf Warteliste
		else if (enrollment.isWaiting) return colors.warningBorder;
		// Angemeldet
		else return colors.warningBorder;
	};

	const handleClipboard = () => {
		navigator.clipboard.writeText(`${window.location.origin}/training/${training.id}`).then(
			function () {
				setInfo(
					'Der Link zu dieser Fortbildung wurde in Ihre Zwischenablage kopiert. Sie können den Link verwenden, um andere über diese Fortbildung zu informieren.'
				);
			},
			function () {
				setError('Ihr Browser unterstützt leider das Kopieren in die Zwischenablage nicht.');
			}
		);
	};




	return (
		<>
			<Card
				bg={'light'}
				text={'dark'}
				style={{
					maxWidth: 		'23rem',
					minWidth:		'23rem',
					height: 		'23rem',
					borderColor:	getBorderColor(),
					borderWidth: 	'2px',
					flexGrow: 		'1',
					marginRight: 	'10px',
					marginLeft: 	'10px',
				}}
				as="li"
				className="shadow-sm  mb-4 bg-white rounded"
			>
				<Card.Header style={{ minHeight: '6.5rem' }}>
					{training.clickable ? (
						<Link to={`/training/${training.id}`} state={{ backgroundLocation: location }}>
							<TrainingHeader as={`h${level}`} color={colors.primarySubHeader}>
								{parse(training.title)}
							</TrainingHeader>
						</Link>
					) : (
						<TrainingHeader color={colors.secondarySubHeader}>
							{parse(training.title)}
						</TrainingHeader>
					)}
					<div
						style={{
							position: 'absolute',
							top: '5.25rem',
							right: '0.25rem',
							fontSize: '75%'
						}}
					>
						{training.eventNumber}
					</div>
				</Card.Header>
				<Card.Body style={{ overflow: 'hidden' }}>
					{!!training.targetGroup && (
						<Row>
							<Col lg={1} md={1} xs={1}>
								<GrGroup alt="Gruppensymbol" />
							</Col>
							<Col as="p" className="m-0" lg={11} md={11} xs={11}>
								{training.targetGroup}
							</Col>
						</Row>
					)}
					{training?.eventDates && (
						<>
							<Row>
								<Col lg={1} md={1} xs={1}>
									<GrHistory alt="Erster Termin Symbol" />
								</Col>
								<Col className="m-0" lg={11} md={11} xs={11}>
									{training.eventDates[0]}
								</Col>
							</Row>
							<Row>
								<Col lg={1} md={1} xs={1}></Col>
								<Col className="m-0" lg={11} md={11} xs={11}>
									{training.eventDates.length < 3 ? (
										training.eventDates[1]
									) : (
										<em>{training.eventDates.length - 1 + ' weitere Termine'}</em>
									)}
								</Col>
							</Row>
						</>
					)}
					<Row>
						<Col lg={1} md={1} xs={1}>
							<GrLocation alt="Fortbildung Ort Symbol" />
						</Col>
						<Col as="p" className="m-0" lg={11} md={11} xs={11}>
							{training.location}
						</Col>
					</Row>
				</Card.Body>

				<Card.Footer>
					{training.clickable ? (
						<Row>
							<Col style={{ textAlign: 'left' }}>
								<TrainingFeedback trainingId={training.id}></TrainingFeedback>
								<HiOutlineClipboardCopy
									style={{ cursor: 'pointer' }}
									onClick={handleClipboard}
									className="mx-1"
									title="Den Link zur Fortbildung kopieren."
									tabIndex="0"
									onKeyDown={(event) => {
										if (event.key === 'Enter') handleClipboard();
									}}
								></HiOutlineClipboardCopy>
								{training.isBookmarked ?  (
										<BsBookmarkDashFill
											style={{ cursor: 'pointer', color: 'green' }}
											onClick={() => delBookmark(training.id)}
											className="mx-1"
											title="Bookmark für diese Fortbildung entfernen"
											tabIndex="1"
										></BsBookmarkDashFill>
									) : (
										<BsBookmarkPlus
											style={{ cursor: 'pointer', color: 'green' }}
											onClick={() => setBookmark(training.id)}
											className="mx-1"
											title="Bookmark für diese Fortbildung hinzufügen"
											tabIndex="1"
										></BsBookmarkPlus>
									)
								}
							</Col>
							<Col xs={'auto'}></Col>
							<Col style={{ textAlign: 'right' }}>
								<Link to={`/training/${training.id}`} state={{ backgroundLocation: location }}>
									<GrZoomIn alt="Detailansicht Symbol" />
								</Link>
							</Col>
						</Row>
					) : (
						'Anmeldung ist im Menü "Fachtage" möglich!'
					)}
				</Card.Footer>
			</Card>
		</>
	);
}
